export const podcastAnalytics = (state) => {
  if (state.selectedPodcast) {
    return state.podcastAnalytics.filter(element => element.PodcastKey === state.selectedPodcast)
  }

  return state.podcastAnalytics
}

export const analyticsSelectedPodcast = (state) => {
  return state.selectedPodcast
}

export const analyticsPeriod = (state) => {
  return state.period
}

