function initialState() {
  return {
    periodStats: null,
    deviceStats: null,
    regionalStats: null,
    royaltyStats: null,

    periodType: "period", // day, period

    params: {
      from_date: "",
      to_date: "",
      offset: "",
    },

    analyticPeriods: [
      {
        label: "Today",
        value: "today",
        period: "hourly",
      },
      {
        label: "This Week",
        value: "thisWeek",
        period: "daily",
      },
      {
        label: "This Month",
        value: "thisMonth",
        period: "daily",
      },
      {
        label: "Yesterday",
        value: "yesterday",
        period: "hourly",
      },
      {
        label: "Previous Week",
        value: "previousWeek",
        period: "daily",
      },
      {
        label: "Previous Month",
        value: "previousMonth",
        period: "daily",
      },
      {
        label: "Custom",
        value: "custom",
        period: "daily",
      },
    ],
  };
}

let state = initialState();

export { initialState, state };
