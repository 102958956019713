import { config } from "/app.config";

const router = [
  {
    path: "analytics",
    name: "analytics",
    component: () => import("@/views/Analytics/Analytics"),
    meta: {
      pageTitle: "Account analytics",
      icon: "BarChart2Icon",
      rule: "owner",
    },
    children: [
      {
        name: "analytics.streaming",
        path: "streaming",
        component: () => import("@/views/Analytics/AnalyticsStreaming"),
        meta: {
          pageTitle: "Streaming analytics",
          parent: "analytics",
          icon: "PhoneIncomingIcon",
          rule: "owner",
        },
      },
      {
        name: "analytics.live",
        path: "live",
        component: () => import("@/views/Analytics/AnalyticsLive"),
        meta: {
          pageTitle: "Live analytics",
          parent: "analytics",
          icon: "ActivityIcon",
          rule: "owner",
        },
      },
      {
        name: "analytics.podcasts",
        path: "podcasts",
        component: () => import("@/views/Analytics/AnalyticsPodcasts"),
        meta: {
          pageTitle: "Podcasts analytics",
          parent: "analytics",
          icon: "MicIcon",
          rule: "owner",
        },
      },
    ],
  },
];

if (config.isZeno) {
  router[0].children.push({
    name: "analytics.ctl",
    path: "ctl",
    component: () => import("@/views/Analytics/AnalyticsCTL"),
    meta: {
      pageTitle: "Call-in analytics",
      parent: "analytics",
      icon: "PhoneIncomingIcon",
      rule: "owner",
    },
  });
}

export default router;
