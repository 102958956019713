import userApi from "@/api/user.api";

export const getUser = async () => {
  return await userApi.get();
};

export const updateUser = async (userData) => {
  return await userApi.update(userData);
};

export const updateUserPassword = async (password) => {
  return await userApi.updatePassword(password);
};

export const getUserProfile = async () => {
  return await userApi.getProfile();
};

export const updateUserProfile = async (data) => {
  return await userApi.updateProfile(data);
};

export const getUserStations = async () => {
  let stations = await userApi.getStations();

  if (stations.length) {
    stations = stations
      .slice()
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  }

  return stations;
};

export const getUserAccounts = async () => {
  let accounts = await userApi.getAccounts();

  if (accounts.length) {
    accounts = accounts
      .slice()
      .sort((a, b) =>
        a.company[0].toLowerCase() > b.company[0].toLowerCase() ? 1 : -1
      );
  }

  return accounts;
};

export default {
  getUser,
  updateUser,
  updateUserPassword,
  getUserProfile,
  updateUserProfile,
  getUserStations,
  getUserAccounts,
};
