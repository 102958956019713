import { config as appConfig } from "../../../app.config";

const portalSettings = {
  path: "settings",
  component: () => import("@/views/Portal/Settings"),
  meta: {
    pageTitle: "Portal settings",
    rule: "portalAdmin",
  },
  children: [
    {
      path: "",
      name: "portal.settings.general",
      component: () =>
        import("@/views/Portal/PortalSettings/PortalSettingsGeneral"),
      meta: {
        pageTitle: "Portal settings",
        parent: "portal.settings",
        rule: "portalAdmin",
      },
    },
  ],
};

if (appConfig.isZeno) {
  portalSettings.children.push({
    path: "notifications",
    name: "portal.notifications",
    component: () =>
      import("@/views/Portal/PortalSettings/PortalNotifications.vue"),
    meta: {
      pageTitle: "Notifications",
      parent: "portal.settings",
      rule: "portalAdmin",
    },
  });

  portalSettings.children.push({
    path: "changelog",
    name: "portal.changelog",
    component: () => import("@/views/Portal/PortalChangelog/PortalChangelog"),
    meta: {
      pageTitle: "Changelog",
      parent: "portal.settings",
      rule: "portalAdmin",
    },
  });
}

export default [portalSettings];
