import toolsClient from "@/api/toolsClient";

const toUrlParams = (data) => {
  const searchParams = new URLSearchParams();

  Object.keys(data).forEach((key) => searchParams.append(key, data[key]));

  return searchParams.toString();
};

export const getStationStreamingStats = async (params, type) => {
  const response = await toolsClient.get(
    `stations/stats/${type}?` + toUrlParams(params)
  );

  return response.data;
};

export const getStationRoyaltyStats = async (params) => {
  let response = await toolsClient.get(
    `stations/metadata/stats/period?` + toUrlParams({stationKeys: params["stationKeys"], from_date: params["from_date"], to_date: params["to_date"]})
  );

  return {royalty_stats: response.data};
};

export const getAccountStreamingStats = async (accountKey, params, type) => {
  const response = await toolsClient.get(
    `accounts/${accountKey}/stats/${type}?` + toUrlParams(params)
  );

  return response.data;
};

export const getAccountRoyaltyStats = async (params, accountKey) => {
  let response = await toolsClient.get(
    `accounts/${accountKey}/metadata/stats/period?` + toUrlParams({from_date: params["from_date"], to_date: params["to_date"]})
  );

  return {royalty_stats: response.data};
};

export const getAccountCTLStats = async (accountKey, params, type) => {
  const response = await toolsClient.get(
    `accounts/${accountKey}/stats/ctl/${type}?` + toUrlParams(params)
  );

  return response.data;
};

export const getStationCTLStats = async (stationKey, params, type) => {
  const response = await toolsClient.get(
    `stations/${stationKey}/stats/ctl/${type}?` + toUrlParams(params)
  );

  return response.data;
};

export const getStationStreamingStatsLive = async (
  mountPoint,
  includeOutputs = false
) => {
  const response = await toolsClient.get(
    `stations/${mountPoint}/stats/live?include_outputs=${includeOutputs}`
  );

  return response.data.data;
};

export const getStationCTLStatsLive = async (mountPoint) => {
  const response = await toolsClient.get(
    `stations/${mountPoint}/stats/ctl/live`
  );

  return response.data;
};

export const getAccountStreamingStatsLive = async (accountKey) => {
  const response = await toolsClient.get(`accounts/${accountKey}/stats/live`);

  return response.data.data;
};

export const getPodcastsStats = async (accountKey, params) => {
  const searchParams = new URLSearchParams();

  Object.keys(params).forEach((key) => searchParams.append(key, params[key]));

  const response = await toolsClient.get(
    `accounts/${accountKey}/stats/podcast/period?` + searchParams.toString()
  );

  return response.data;
};
