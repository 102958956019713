import toolsClient from "@/api/toolsClient";

const search = async (query, limit = 24, offset = 0) => {
  const { data } = await toolsClient.get(
    `accounts/search/?limit=${limit}&offset=${offset}&output=list&search=${query}`
  );

  return data;
};

const list = async (pageNumber = 0 , pageSize = 10, sortBy = 'name', sortDesc = false, search) => {
  let path = `accounts/list?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDesc=${sortDesc}`
  if (search){
    path += `&search=${search}`
  }
  const { data } = await toolsClient.get(path);

  return data;
};

const get = async (id) => {
  const { data } = await toolsClient.get(`accounts/${id}`);

  return data;
};

const store = async (accountData) => {
  const { data } = await toolsClient.post("accounts/", accountData);

  return data;
};

const update = async (accountData) => {
  const { data } = await toolsClient.put(
    `accounts/${accountData.key}`,
    accountData
  );

  return data;
};

const destroy = async (id) => {
  const { data } = await toolsClient.delete(`portal/accounts/${id}`);

  return data;
};

const add = async (accountData) => {
  const { data } = await toolsClient.post("portal/accounts/", accountData);

  return data;
};

const suspend = async (id) => {
  const { data } = await toolsClient.put(`accounts/${id}/suspend`);

  return data;
};

const reactivate = async (id) => {
  const { data } = await toolsClient.put(`accounts/${id}/reactivate`);

  return data;
};

const getStations = async (id, limit = 1000, offset = 0, deleted = false) => {
  const { data } = await toolsClient.get(
    `accounts/${id}/stations/search?limit=${limit}&offset=${offset}&deleted=${deleted}`
  );

  return data.entities;
};

const getUndeleteStation = async(id, stationKey) =>{
  const { data } = await toolsClient.get(`accounts/${id}/undeleteStation/${stationKey}`);

  return data;
}

const updateUndeleteStation = async(id, stationKey) =>{
  const { data } = await toolsClient.put(`accounts/${id}/undeleteStation/${stationKey}`);

  return data;
}

const getCtlStations = async (id) => {
  const { data } = await toolsClient.get(`accounts/${id}/ctlstations`);

  return data;
};

const getCustomLimits = async (id) => {
  const { data } = await toolsClient.get(`accounts/${id}/customLimits`);

  return data;
};

const updateCustomLimits = async (customLimitsData) => {
  const { data } = await toolsClient.put(`accounts/${customLimitsData.accountKey}/customLimits`,customLimitsData);

  return data;
};

const createAudioAdvertisingRequest = async (id, audioAdvertisingData) => {
  const formData = new FormData()
  
  formData.append("formData", JSON.stringify(audioAdvertisingData.formData))
  formData.append("file", audioAdvertisingData.file)

  const { data } = await toolsClient.post(`accounts/${id}/audioadvertising/request/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  return data
}

export default {
  search,
  list,
  get,
  store,
  update,
  destroy,
  add,
  suspend,
  reactivate,
  getStations,
  getUndeleteStation,
  updateUndeleteStation,
  getCtlStations,
  getCustomLimits,
  updateCustomLimits,
  createAudioAdvertisingRequest
};
