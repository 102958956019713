function initialState() {
  return {
    podcastAnalytics: null,
    selectedPodcast: null,
    period: {
      from_date: "",
      to_date: ""
    }
  }
}

let state = initialState()

export {
  initialState,
  state,
}
