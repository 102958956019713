import { getPodcastsStats } from "@/api/analytics.api";

export const getPodcastAnalytics = async (context, { accountId, params }) => {
  const podcastAnalytics = await getPodcastsStats(accountId, params);

  context.commit("setPodcastAnalytics", podcastAnalytics);
};

export const selectPodcast = async (context, payload) => {
  context.commit("setSelectedPodcast", payload);
};

export const setAnalyticsPeriod = async (context, payload) => {
  context.commit("setPeriod", payload);
};

export const resetPodcastAnalytics = async (context) => {
  context.commit("reset");
};
