import toolsClient from "@/api/toolsClient";

const list = async (id, type = "accounts") => {
  const { data } = await toolsClient.get(`/${type}/${id}/getSubscriptions/`);

  return data;
};

const get = async (id, priceId, type = "accounts") => {
  const { data } = await toolsClient.get(
    `/${type}/${id}/getSubscription/${priceId}`
  );

  return data;
};

const create = async (id, payload, type = "accounts") => {
  const { data } = await toolsClient.post(
    `/${type}/${id}/createSubscription`,
    payload
  );

  return data;
};

const cancel = async (id, priceId, type = "accounts") => {
  const { data } = await toolsClient.delete(
    `/${type}/${id}/cancelSubscription/${priceId}`
  );

  return data;
};

export const getAccountPlans = async () => {
  const { data } = await toolsClient.get(`/accounts/plansDiscounts`);

  return data;
};

export const getAccountPlansFeatures = async (id) => {
  const { data } = await toolsClient.get(`/accounts/${id}/planFeatures`);

  return data;
};

export const canUpdateSubscription = async (id, priceId) => {
  const { data } = await toolsClient.get(
    `/accounts/${id}/canUpdateSubscription?updatePriceId=${priceId}`
  );

  return data;
};

export default {
  list,
  get,
  create,
  cancel,
  getAccountPlans,
  getAccountPlansFeatures,
  canUpdateSubscription,
};
