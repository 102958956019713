export const SUBSCRIPTION_ACCOUNT_STARTER =
  process.env.VUE_APP_STRIPE_SUBSCRIPTION_ACCOUNT_STARTER;
export const SUBSCRIPTION_ACCOUNT_STARTER_MONTH =
  process.env.VUE_APP_STRIPE_SUBSCRIPTION_ACCOUNT_STARTER_MONTH;
export const SUBSCRIPTION_ACCOUNT_STARTER_YEAR =
  process.env.VUE_APP_STRIPE_SUBSCRIPTION_ACCOUNT_STARTER_YEAR;

export const SUBSCRIPTION_ACCOUNT_PRIME =
  process.env.VUE_APP_STRIPE_SUBSCRIPTION_ACCOUNT_PRIME;
export const SUBSCRIPTION_ACCOUNT_PRIME_MONTH =
  process.env.VUE_APP_STRIPE_SUBSCRIPTION_ACCOUNT_PRIME_MONTH;
export const SUBSCRIPTION_ACCOUNT_PRIME_YEAR =
  process.env.VUE_APP_STRIPE_SUBSCRIPTION_ACCOUNT_PRIME_YEAR;

export const SUBSCRIPTION_ACCOUNT_PRIME_ADS_FREE =
  process.env.VUE_APP_STRIPE_SUBSCRIPTION_ACCOUNT_PRIME_ADS_FREE;
export const SUBSCRIPTION_ACCOUNT_PRIME_ADS_FREE_MONTH =
  process.env.VUE_APP_STRIPE_SUBSCRIPTION_ACCOUNT_PRIME_ADS_FREE_MONTH;
export const SUBSCRIPTION_ACCOUNT_PRIME_ADS_FREE_YEAR =
  process.env.VUE_APP_STRIPE_SUBSCRIPTION_ACCOUNT_PRIME_ADS_FREE_YEAR;

export const SUBSCRIPTION_ACCOUNT_PREMIER =
  process.env.VUE_APP_STRIPE_SUBSCRIPTION_ACCOUNT_PREMIER;
export const SUBSCRIPTION_ACCOUNT_PREMIER_MONTH =
  process.env.VUE_APP_STRIPE_SUBSCRIPTION_ACCOUNT_PREMIER_MONTH;
export const SUBSCRIPTION_ACCOUNT_PREMIER_YEAR =
  process.env.VUE_APP_STRIPE_SUBSCRIPTION_ACCOUNT_PREMIER_YEAR;

export const SUBSCRIPTION_ACCOUNT_ZENO_APP =
  process.env.VUE_APP_STRIPE_SUBSCRIPTION_ACCOUNT_ZENO_APP;

function initialState() {
  return {
    accountSubscriptions: null,
    accountPlans: null,
  };
}

let state = initialState();

export { initialState, state };
