import {initialState} from "./state"

export const setPodcastAnalytics = (state, payload) => {
  state.podcastAnalytics = payload
}

export const setSelectedPodcast = (state, payload) => {
  state.selectedPodcast = payload
}

export const setPeriod = (state, payload) => {
  state.period = payload
}

export const reset = (state) => {
  const s = initialState()

  Object.keys(s).forEach(key => {
    state[key] = s[key]
  })
}
